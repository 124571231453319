import { useState, useLayoutEffect } from "react";
import { Button } from "@douyinfe/semi-ui";
import { useNavigate } from "react-router-dom";
import logoImg from "@assets/home-image/logo.svg";
import globalIcon from "@assets/home-image/global.png";
import { RootState, AppDispatch } from "@state/store";
import { useSelector, useDispatch } from "react-redux";
import { headerConfig } from "@services/home/config/home.config";
import { setLang } from "@state/home/reducer";
import { useSearchParams } from "react-router-dom";
import { HeaderLanModel } from "@services/home/model/home.model";
import styles from "./index.module.css";

const HomeHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = useSelector((state: RootState) => state.home.lang);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [config, setConfig] = useState<HeaderLanModel>();

  const language = searchParams.get("lang");

  function onChangeLan() {
    if (lang === "en") {
      dispatch(setLang("cn"));
      setSearchParams((params) => {
        params.set("lang", "cn");
        return params;
      });
    } else {
      dispatch(setLang("en"));
      setSearchParams((params) => {
        params.set("lang", "en");
        return params;
      });
    }
  }

  function login() {
    navigate("/login");
  }

  function applyTrial() {
    navigate("/index/trial");
  }

  useLayoutEffect(() => {
    if (language) {
      dispatch(setLang(language === "cn" ? "cn" : "en"));
    } else {
      const userLan = navigator.language;
      const isChinese =
        userLan === "zh-CN" || userLan === "zh" || userLan === "zh-TW";

      if (!lang) {
        dispatch(setLang(isChinese ? "cn" : "en"));
      }
    }
  }, [lang]);

  useLayoutEffect(() => {
    if (lang) {
      setConfig(headerConfig[lang]);
    }
  }, [lang]);

  return (
    <div className={styles.header}>
      {config && (
        <div className={styles.wrapper}>
          <div>
            <a href="https://www.biogeom.com" target="_black">
              <img src={logoImg} />
            </a>
          </div>
          <div className={styles.right}>
            <span onClick={onChangeLan} className={styles.lanWrapper}>
              <img className={styles.globalImg} src={globalIcon} />
              <span style={{ marginLeft: "10px" }}>{config.lan}</span>
            </span>
            <Button
              theme="solid"
              type="primary"
              className={styles.btn}
              onClick={applyTrial}
            >
              {config.applyText}
            </Button>
            <Button
              theme="light"
              type="tertiary"
              className={`reset-btn ${styles.btn}`}
              onClick={login}
            >
              {config.btnText}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeHeader;
