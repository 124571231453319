import React, { useEffect, useState } from "react";
import {
  Layout,
  Nav,
  Button,
  Avatar,
  Dropdown,
  Divider,
  Popover,
  Tooltip,
} from "@douyinfe/semi-ui";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotificationsIcon } from "@assets/icons/notifications.svg";
import { ReactComponent as SupportIcon } from "@assets/icons/support.svg";
import styles from "./header.module.css";
import { logoutApi } from "@services/login/login.api";
import {
  getLoginUserInfo,
  cancelRedDotApi,
} from "@services/user-manage/user.api";
import { removeLocalStorage, getLocalStorage } from "@utils/localstorage";
import { LoginEnum } from "@services/login/enum/login.enum";
import logoImage from "@assets/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@state/store";
import { encrypt } from "@utils/encryption.util";
import { JobTypeEnum, JobGroupEnum } from "@services/job/enum/job.enum";
import {
  JOB_TYPE_LABEL,
  JOB_GROUP_LABEL,
} from "@services/job/config/job.config";
import { deleteUser, changeRedDot } from "@state/global/reducer";
import NotificationScrollList from "@components/notification-scroll-list";
import {
  setJobPanelVisible,
  setSelectJobType,
} from "@state/protein-editor/reducer";
import { createSocketInstanceApi } from "@services/editor/editor.api";
import type { Socket } from "socket.io-client";
import { TokenModel } from "@services/login/model/login.model";
import { DOC_URL } from "@config/global.config";
import FeedbackDialog from "@components/feedback-dialog";
import { Link } from "react-router-dom";

const { Header } = Layout;

const HeaderSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [feedbackVisible, setFeedbackVisible] = useState(false);

  const selectJobType = useSelector(
    (state: RootState) => state.editor.selectJobType
  );

  const loginUser = useSelector((state: RootState) => state.global.loginUser);

  const navigate = useNavigate();
  const { data: userInfo } = useRequest(getLoginUserInfo);

  function logout() {
    logoutApi()
      .then(() => {
        removeLocalStorage(LoginEnum.Token);
        dispatch(deleteUser());
        navigate("/login");
      })
      .catch(() => {});
  }

  function onNavChange(e: {
    itemKey?: React.ReactText | undefined;
    domEvent?: MouseEvent | undefined;
    isOpen?: boolean | undefined;
  }) {
    const k = e.itemKey;
    if (
      k === JobGroupEnum.Utils ||
      k === JobGroupEnum.AntibodyDesign ||
      k === JobGroupEnum.AffinityOptimization ||
      k === JobGroupEnum.StructureModeling ||
      k === JobGroupEnum.ProteinDesign
    ) {
      return;
    }
    dispatch(setJobPanelVisible(true));
    if (e.itemKey) {
      dispatch(setSelectJobType(e.itemKey as JobTypeEnum));
    }
  }

  function clearRedDot() {
    cancelRedDotApi()
      .then(() => {
        dispatch(changeRedDot(0));
      })
      .catch(() => {});
  }

  function onSupport() {
    const loginToken = getLocalStorage(LoginEnum.Token) as TokenModel;

    window.open(
      `${DOC_URL}/index.html?referer=${encodeURIComponent(
        window.location.href
      )}&token=${encodeURIComponent(encrypt(loginToken.token))}`,
      "_blank"
    );
  }

  function toHome() {
    navigate("/dashboard/projects");
  }

  function onFeedbackCancel() {
    setFeedbackVisible(false);
  }

  function onFeedbackSubmit() {
    setFeedbackVisible(false);
  }

  function openFeedbackDialog() {
    setFeedbackVisible(true);
  }

  useEffect(() => {
    let socket: Socket;

    const loginToken = getLocalStorage(LoginEnum.Token) as TokenModel;

    if (loginToken?.token) {
      socket = createSocketInstanceApi(loginToken.token);
      socket.on("message", (data: any) => {
        console.log("message", data);
        dispatch(changeRedDot(1));
      });

      socket.on("connect", () => {
        console.log("connect", socket);
      });

      socket.on("connect_error", (e: any) => {
        console.log("connect_error", e);
      });
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [loginUser]);

  return (
    <Header style={{ backgroundColor: "var(--semi-color-bg-1)" }}>
      <Nav
        header={{
          logo: (
            <img
              src={logoImage}
              style={{ cursor: "pointer" }}
              onClick={toHome}
            />
          ),
          text: (
            <span className={styles.headTitle} onClick={toHome}>
              GeoBiologics
            </span>
          ),
        }}
        mode="horizontal"
        footer={
          <>
            <Dropdown
              trigger={"click"}
              showTick
              // visible
              position={"bottomRight"}
              render={<NotificationScrollList />}
            >
              <div className={styles.btnWrapper}>
                <Tooltip content="Notifications">
                  <Button
                    theme="borderless"
                    icon={<NotificationsIcon />}
                    className={styles.iconBtn}
                    style={{ marginRight: "12px" }}
                    onClick={clearRedDot}
                  />
                </Tooltip>

                {loginUser?.red_dot === 1 && (
                  <span className={styles.redDot}></span>
                )}
              </div>
            </Dropdown>

            <Tooltip content="Support">
              <Button
                theme="borderless"
                icon={<SupportIcon />}
                className={styles.iconBtn}
                style={{ marginRight: "20px" }}
                onClick={onSupport}
              />
            </Tooltip>

            <Popover
              position="bottom"
              showArrow
              style={{
                padding: "0px",
              }}
              trigger="click"
              clickToHide
              content={
                <Dropdown.Menu className={styles.dropdownContainer}>
                  <Dropdown.Item>
                    <Link to="/dashboard/profile">
                      <div className={styles.avatarContainer}>
                        <Avatar
                          color="red"
                          size="small"
                          src={userInfo?.data.avatar}
                        >
                          {userInfo?.data.username.slice(0, 2)}
                        </Avatar>
                        <div className={styles.right}>
                          <div>{userInfo?.data.username}</div>
                          <div className={styles.email}>
                            {userInfo?.data.email}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Dropdown.Item>
                  <Divider style={{ margin: 0 }} />
                  <Dropdown.Item>
                    <Link to="/dashboard/projects">Projects</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/dashboard/jobs">Jobs</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/dashboard/files">Files</Link>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openFeedbackDialog}>
                    Share your feedback
                  </Dropdown.Item>
                  <Divider style={{ margin: 0 }} />
                  <Dropdown.Item onClick={logout}>
                    <div className={styles.signout}>Sign Out</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              }
            >
              <Avatar color="red" size="small" src={userInfo?.data.avatar}>
                {userInfo?.data.username.slice(0, 2)}
              </Avatar>
            </Popover>
          </>
        }
        onClick={onNavChange}
        selectedKeys={selectJobType ? [selectJobType] : []}
      >
        <Nav.Sub
          itemKey={JobGroupEnum.Utils}
          text={JOB_GROUP_LABEL[JobGroupEnum.Utils]}
        >
          <Nav.Item
            itemKey={JobTypeEnum.AbNumbering}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbNumbering]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.ProteinStructureAlign}
            text={JOB_TYPE_LABEL[JobTypeEnum.ProteinStructureAlign]}
          />
        </Nav.Sub>
        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.StructureModeling}
          text={JOB_GROUP_LABEL[JobGroupEnum.StructureModeling]}
        >
          <Nav.Item
            itemKey={JobTypeEnum.GenStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.GenStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.AbStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.AbAgDocking}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbAgDocking]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.NanobodyStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.NanobodyStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.TcrStructPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.TcrStructPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.EpitopePredict}
            text={JOB_TYPE_LABEL[JobTypeEnum.EpitopePredict]}
          />
        </Nav.Sub>
        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.AffinityOptimization}
          text={JOB_GROUP_LABEL[JobGroupEnum.AffinityOptimization]}
        >
          <Nav.Item
            itemKey={JobTypeEnum.AffinityOptim}
            text={JOB_TYPE_LABEL[JobTypeEnum.AffinityOptim]}
          />
        </Nav.Sub>
        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.AntibodyDesign}
          text={JOB_GROUP_LABEL[JobGroupEnum.AntibodyDesign]}
          data-sub
        >
          <Nav.Item
            itemKey={JobTypeEnum.OptimizationAntibody}
            text={JOB_TYPE_LABEL[JobTypeEnum.OptimizationAntibody]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.DnAbDesign}
            text={JOB_TYPE_LABEL[JobTypeEnum.DnAbDesign]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.DevPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.DevPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.HumanPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.HumanPred]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.Humanization}
            text={JOB_TYPE_LABEL[JobTypeEnum.Humanization]}
          />
          {/* <Nav.Item
            itemKey={JobTypeEnum.AffinityPred}
            text={JOB_TYPE_LABEL[JobTypeEnum.AffinityPred]}
          /> */}
          {/* <Nav.Item
            itemKey={JobTypeEnum.AbLightChainRetrieval}
            text={JOB_TYPE_LABEL[JobTypeEnum.AbLightChainRetrieval]}
          /> */}
        </Nav.Sub>

        <Nav.Sub
          dropdownStyle={{ fontWeight: "normal" }}
          itemKey={JobGroupEnum.ProteinDesign}
          text={JOB_GROUP_LABEL[JobGroupEnum.ProteinDesign]}
        >
          {/* <Nav.Item
            itemKey={JobTypeEnum.DevOptim}
            text={JOB_TYPE_LABEL[JobTypeEnum.DevOptim]}
          /> */}
          <Nav.Item
            itemKey={JobTypeEnum.ProteinMpnn}
            text={JOB_TYPE_LABEL[JobTypeEnum.ProteinMpnn]}
          />

          <Nav.Item
            itemKey={JobTypeEnum.EnzymeOptimize}
            text={JOB_TYPE_LABEL[JobTypeEnum.EnzymeOptimize]}
          />
          <Nav.Item
            itemKey={JobTypeEnum.MHC2BindPredict}
            text={JOB_TYPE_LABEL[JobTypeEnum.MHC2BindPredict]}
          />
        </Nav.Sub>
      </Nav>

      {feedbackVisible && (
        <FeedbackDialog cancel={onFeedbackCancel} submit={onFeedbackSubmit} />
      )}
    </Header>
  );
};

export default HeaderSection;
