import { FileModel } from "@services/file/model/file.model";
import {
  PROPERTIES_MAP,
  PROPERTIES_PLUS_LIST,
  HIGLIGHT_COLOR,
} from "@services/editor/config/editor.config";
import {
  HumanizationSummaryModel,
  HumannessSummaryModel,
  TreeNodeModel,
  FileInfoModel,
  FileJobDataModel,
  AntibodyOptimizationParametersModel,
} from "@services/editor/model/editor.model";
import {
  MutationModel,
  ChainType,
  PositionModel,
  NameSeqModel,
  GermlinePositionModel,
  AdviserGermlinePosRecResultModel,
  PtmRiskChainModel,
  PtmPosAttrModel,
  OptimizationAntibodyMutationModel,
  ScoreChainInfoModel,
  SurfaceChainModel,
  SurfacePosAttrModel,
  AAFrequencyModel,
  AntibodyRecommendModel,
  AdviserGermlineSequenceModel,
  ChainSeqPosModel,
} from "@services/editor/model/job-result.model";
import {
  TreeItemTypeEnum,
  SchemeEnum,
  SeqAlignTypeEnum,
  AdvisorEnum,
} from "@services/editor/enum/editor.enum";
import { PluginUIContext } from "@mol-ui/context";
import { OrderedSet } from "molstar/lib/mol-data/int";
import { Toast } from "@douyinfe/semi-ui";
import {
  ChainInfoModel,
  ChainMapModel,
} from "@services/editor/model/job-panel.model";
import type { ResidueIndex } from "molstar/lib/mol-model/structure";
import { InteractionEffectModel } from "@services/editor/model/job-panel.model";
import { interactionTypeLabel } from "molstar/lib/mol-model-props/computed/interactions/common";

export function fileMap(item: FileModel): TreeNodeModel {
  return {
    label: item.name,
    value: item.id,
    key: item.id,
    source: item.source,
    path: item.path,
    type: "file",
    job_type: item.job_type,
  };
}

export function aggregateData(
  fileJobData: FileJobDataModel,
  fileTreeList: TreeNodeModel[]
) {
  const { list, checkpoint } = fileJobData;

  const map = fileTreeList.reduce((ac, item) => {
    ac[item.key] = item;
    return ac;
  }, {} as Record<string, TreeNodeModel>);

  const data: TreeNodeModel[] = list.map((item) => {
    if ("job_info" in item) {
      const o: TreeNodeModel = {
        label: item.job_info.job_name,
        value: item.job_info.id,
        key: item.job_info.id,
        status: item.job_info.status,
        children:
          item.job_info && map[item.job_info.id]
            ? map[item.job_info.id].children
            : [],
        type: item.type,
        job_type: item.job_info.job_type_name,
      };
      return o;
    } else if ("file_info" in item) {
      const o: TreeNodeModel = {
        label: item.file_info.name,
        value: item.file_info.id,
        key: item.file_info.id,
        type: item.type,
        isLeaf: true,
      };
      return o;
    } else {
      const o: TreeNodeModel = {
        label: item.dataset_info.name,
        value: item.dataset_info.id,
        key: item.dataset_info.id,
        type: item.type,
        isLeaf: true,
      };
      return o;
    }
  });

  const filesMap = list
    .filter((item) => {
      return item.type === TreeItemTypeEnum.File;
    })
    .reduce((ac, item) => {
      if ("file_info" in item) {
        ac[item.file_info.id] = item.file_info;
      }
      return ac;
    }, {} as Record<string, FileInfoModel>);

  return {
    treeList: data,
    filesMap,
    checkpoint,
  };
}

export function convertProperties(arr: string[][]): string[] {
  return arr.reduce((result: string[], options) => {
    if (options.length === 1) {
      const keys = Object.keys(PROPERTIES_MAP).reduce((ac: string[], k) => {
        const vals = PROPERTIES_MAP[k].map((item) => item.value);
        return [...ac, ...vals];
      }, []);

      return [...result, ...keys];
    } else if (options.length === 2) {
      const [, key] = options;
      const vals = PROPERTIES_MAP[key].map((item) => item.value);

      return [...result, ...vals];
    } else {
      const [, , val] = options;
      return [...result, val];
    }
  }, []);
}

export function convertPropertiesV2(arr: string[][]): string[] {
  return arr.reduce((result: string[], options) => {
    if (options.length === 1) {
      const keys = PROPERTIES_PLUS_LIST.map((option) => {
        return option.value;
      });

      return [...result, ...keys];
    } else {
      const [, key] = options;

      return [...result, key];
    }
  }, []);
}

function getValue(data: string[], map: Record<string, number>, target: string) {
  if (!map[target]) {
    return null;
  }

  if (!data[map[target]]) {
    return null;
  }
  return data[map[target]] as unknown as number | null;
}

function getArray(data: string[], map: Record<string, number>, target: string) {
  if (!map[target]) {
    return [];
  }

  if (!data[map[target]]) {
    return [];
  }

  const res = JSON.parse(data[map[target]]) as string[];

  return res;
}

export function getHumanizationSummary(
  item: string[],
  titleMapToIndex: Record<string, number>
) {
  const detail: HumanizationSummaryModel = {
    name: `${item[0]}`,
    old_humanness_h: getValue(item, titleMapToIndex, "old humanness|H"),
    old_humanness_l: getValue(item, titleMapToIndex, "old humanness|L"),
    old_germline_content_h: getValue(
      item,
      titleMapToIndex,
      "old germline content|H"
    ),
    old_germline_content_l: getValue(
      item,
      titleMapToIndex,
      "old germline content|L"
    ),
    old_percentile_h: getValue(item, titleMapToIndex, "old percentile|H"),
    old_percentile_l: getValue(item, titleMapToIndex, "old percentile|L"),
    new_humanness_h: getValue(item, titleMapToIndex, "new humanness|H"),
    new_humanness_l: getValue(item, titleMapToIndex, "new humanness|L"),
    new_germline_content_h: getValue(
      item,
      titleMapToIndex,
      "new germline content|H"
    ),
    new_germline_content_l: getValue(
      item,
      titleMapToIndex,
      "new germline content|L"
    ),
    new_percentile_h: getValue(item, titleMapToIndex, "new percentile|H"),
    new_percentile_l: getValue(item, titleMapToIndex, "new percentile|L"),
    preservation_h: getValue(item, titleMapToIndex, "preservation|H"),
    preservation_l: getValue(item, titleMapToIndex, "preservation|L"),
    humanness_improvement_h: getValue(
      item,
      titleMapToIndex,
      "humanness improvement|H"
    ),
    humanness_improvement_l: getValue(
      item,
      titleMapToIndex,
      "humanness improvement|L"
    ),
    old_humanness_all: getValue(item, titleMapToIndex, "old humanness|all"),
    new_humanness_all: getValue(item, titleMapToIndex, "new humanness|all"),
    old_germline_content_all: getValue(
      item,
      titleMapToIndex,
      "old germline content|all"
    ),
    new_germline_content_all: getValue(
      item,
      titleMapToIndex,
      "new germline content|all"
    ),
    old_FR_germline_content_all: getValue(
      item,
      titleMapToIndex,
      "old FR germline content|all"
    ),
    new_FR_germline_content_all: getValue(
      item,
      titleMapToIndex,
      "new FR germline content|all"
    ),
    germline_allele_all: getValue(item, titleMapToIndex, "germline allele|all"),
    old_percentile_all: getValue(item, titleMapToIndex, "old percentile|all"),
    new_percentile_all: getValue(item, titleMapToIndex, "new percentile|all"),
    preservation_all: getValue(item, titleMapToIndex, "preservation|all"),
    humanness_improvement_all: getValue(
      item,
      titleMapToIndex,
      "humanness improvement|all"
    ),
    file_ids: getArray(item, titleMapToIndex, "file_ids"),
  };

  return detail;
}

export function getHumannessSummary(
  item: string[],
  titleMapToIndex: Record<string, number>
) {
  const detail: HumannessSummaryModel = {
    name: item[0],
    humanness_h: getValue(item, titleMapToIndex, "humanness|H"),
    humanness_l: getValue(item, titleMapToIndex, "humanness|L"),
    germline_content_h: getValue(item, titleMapToIndex, "germline content|H"),
    germline_content_l: getValue(item, titleMapToIndex, "germline content|L"),
    percentile_h: getValue(item, titleMapToIndex, "percentile|H"),
    percentile_l: getValue(item, titleMapToIndex, "percentile|L"),
    humanness_all: getValue(item, titleMapToIndex, "humanness|all"),
    percentile_all: getValue(item, titleMapToIndex, "percentile|all"),
    germline_content_all: getValue(
      item,
      titleMapToIndex,
      "germline content|all"
    ),
    file_ids: getArray(item, titleMapToIndex, "file_ids"),
  };

  return detail;
}

export function getColor(score: number) {
  const idx = Math.floor(score * 10) - 2;
  if (idx < 0) {
    return "";
  }
  return HIGLIGHT_COLOR[idx];
}

// 函数：将 [0, 1] 区间映射到 [75, 100] 区间
/*
  mapRange(inputValue, 0, 1, 75, 100);
*/
export function mapRange(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) {
  // 将输入值限制在输入区间内
  value = Math.min(Math.max(value, inMin), inMax);

  // 计算映射后的值
  const mappedValue =
    ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

  return mappedValue;
}

export function hslToHex(h: number, s: number, l: number) {
  h /= 360;
  s /= 100;
  l /= 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l; // 饱和度为0时，颜色为灰色
  } else {
    const hue2rgb = function hue2rgb(p: number, q: number, t: number) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = function toHex(x: number) {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function generateColor(score: number) {
  // 固定饱和度和亮度，改变色调生成不同颜色
  const hue = 260;
  const saturation = 100; // 饱和度
  const lightness = mapRange(1 - score, 0, 1, 75, 100); // 亮度

  // 将HSL颜色转换为十六进制
  const hexColor = hslToHex(hue, saturation, lightness);

  return hexColor;
}

export function getSequenceList(str: string): NameSeqModel[] {
  let s = str.trim();
  s = s.substring(1);
  const arr = s.split("\n>");
  const list = arr.map((block) => {
    const indexOfNewline = block.indexOf("\n");
    const name = block.substring(0, indexOfNewline);
    const seq = block.substring(indexOfNewline).replace(/\n/g, "");
    return {
      name,
      seq,
    };
  });

  return list;
}

export function getRevertedSequence(
  deletedItems: MutationModel[],
  sequenceRaw: string,
  chainHPositionArray: string[],
  chainLPositionArray: string[],
  chainHNew?: ScoreChainInfoModel,
  chainLNew?: ScoreChainInfoModel
) {
  const chainHPatchs = deletedItems
    .filter((item) => {
      return item.chainName === "H";
    })
    .reduce((ac, item) => {
      ac[item.pos] = item.origin;
      return ac;
    }, {} as Record<string, string>);

  const chainLPatchs = deletedItems
    .filter((item) => {
      return item.chainName === "L";
    })
    .reduce((ac, item) => {
      ac[item.pos] = item.origin;
      return ac;
    }, {} as Record<string, string>);

  if (chainHNew && chainLNew) {
    const chainHNewMap = chainHNew.seq_items.reduce((ac, item) => {
      ac[item.pos] = item;
      return ac;
    }, {} as Record<string, ChainSeqPosModel>);

    const chainLNewMap = chainLNew.seq_items.reduce((ac, item) => {
      ac[item.pos] = item;
      return ac;
    }, {} as Record<string, ChainSeqPosModel>);

    const chainH = chainHPositionArray.map((pos) => {
      if (chainHPatchs[pos]) {
        return chainHPatchs[pos];
      }
      return chainHNewMap[pos] ? chainHNewMap[pos].aa : "-";
    });

    const chainL = chainLPositionArray.map((pos) => {
      if (chainLPatchs[pos]) {
        return chainLPatchs[pos];
      }

      return chainLNewMap[pos] ? chainLNewMap[pos].aa : "-";
    });

    const list = getSequenceList(sequenceRaw);

    const seqList = [
      `>${list[0].name}`,
      chainH.join("").replace(/-/g, ""),
      `>${list[1].name}`,
      chainL.join("").replace(/-/g, ""),
    ];

    return seqList.join("\n");
  }

  return "";
}

export function getModifiedSequence(
  newName: string,
  pos: string,
  selectedChain: ChainType,
  sequenceRaw: string,
  chainHPositionArray: string[],
  chainLPositionArray: string[],
  chainHNew?: ScoreChainInfoModel,
  chainLNew?: ScoreChainInfoModel
) {
  if (!chainHNew || !chainLNew) {
    return "";
  }

  const list = getSequenceList(sequenceRaw);

  const chainHNewMap = chainHNew.seq_items.reduce((ac, item) => {
    ac[item.pos] = item;
    return ac;
  }, {} as Record<string, ChainSeqPosModel>);

  const chainLNewMap = chainLNew.seq_items.reduce((ac, item) => {
    ac[item.pos] = item;
    return ac;
  }, {} as Record<string, ChainSeqPosModel>);

  let chainH = "";
  let chainL = "";

  if (selectedChain === "H") {
    const chainHSeq = chainHPositionArray.map((p) => {
      if (p === pos) {
        return newName;
      }
      return chainHNewMap[p] ? chainHNewMap[p].aa : "-";
    });

    const chainLSeq = chainLPositionArray.map((p) => {
      return chainLNewMap[p] ? chainLNewMap[p].aa : "-";
    });

    chainH = chainHSeq.join("");
    chainL = chainLSeq.join("");
  } else {
    const chainHSeq = chainHPositionArray.map((p) => {
      return chainHNewMap[p] ? chainHNewMap[p].aa : "-";
    });

    const chainLSeq = chainLPositionArray.map((p) => {
      if (p === pos) {
        return newName;
      }
      return chainLNewMap[p] ? chainLNewMap[p].aa : "-";
    });

    chainH = chainHSeq.join("");
    chainL = chainLSeq.join("");
  }

  const seqList = [
    `>${list[0].name}`,
    chainH.replace(/-/g, ""),
    `>${list[1].name}`,
    chainL.replace(/-/g, ""),
  ];

  return seqList.join("\n");
}

export function getModifiedSequenceWithOneChain(
  sequenceRaw: string,
  editedHSequenceArr: string[],
  editedLSequenceArr: string[]
) {
  const list = getSequenceList(sequenceRaw);

  const chainH = editedHSequenceArr.join("");
  const chainL = editedLSequenceArr.join("");

  const seqList = [
    `>${list[0].name}`,
    chainH.replace(/-/g, ""),
    `>${list[1].name}`,
    chainL.replace(/-/g, ""),
  ];

  return seqList.join("\n");
}

export function transpose(
  matrix: PositionModel[],
  seq_align_type: SeqAlignTypeEnum
) {
  return matrix[0].aa_frequency.map((col, i) => {
    return matrix.reduce((ac, row, idx) => {
      ac[seq_align_type === SeqAlignTypeEnum.ByPos ? row.pos : `${idx}`] = {
        ...row.aa_frequency[i],
        pos: seq_align_type === SeqAlignTypeEnum.ByPos ? row.pos : `${idx}`,
        seq_align_type,
      };

      return ac;
    }, {} as Record<string, AAFrequencyModel>);
  });
}

export function transformRecommendData(
  posRecResult: PositionModel[],
  seq_align_type: SeqAlignTypeEnum
) {
  const matrix = transpose(posRecResult, seq_align_type);

  return matrix.slice(0, 10);
}

export function removeLineBreak(str: string) {
  return str.replace(/\n/g, "");
}

export function updateTreeData(
  list: TreeNodeModel[],
  key: string,
  children: TreeNodeModel[]
): TreeNodeModel[] {
  return list.map((node) => {
    if (node.key === key) {
      return { ...node, children };
    }
    // if (node.children) {
    //   return {
    //     ...node,
    //     children: updateTreeData(node.children, key, children),
    //   };
    // }
    return node;
  });
}

export function updateTreeWithMap(
  list: TreeNodeModel[],
  map: Record<string, TreeNodeModel[]>
) {
  return list.map((node) => {
    return {
      ...node,
      children: map[node.key] ? map[node.key] : node.children,
    };
  });
}

export function getChainText(
  info: ChainInfoModel,
  map: Record<string, string[]>
) {
  const len = info.sequences.length;
  let start = -2;
  let end = -2;
  const selectionArr: string[] = [];

  for (let i = 0; i < len; i++) {
    const seqIdx = info.sequences[i];
    const code = info.inscodes[i];
    if (map[seqIdx].length > 1) {
      if (start !== -2 && end !== -2) {
        selectionArr.push(`${info.name}:${start}-${end}`);
        start = -2;
        end = -2;
      } else if (start !== -2) {
        selectionArr.push(`${info.name}:${start}`);
      }

      start = -2;
      end = -2;
      selectionArr.push(`${info.name}:${seqIdx}${code}`);
    } else {
      if (start === -2) {
        start = seqIdx;
      }
      const nextSeqIdx = info.sequences[i + 1];
      if (typeof nextSeqIdx === "undefined") {
        if (start !== -2 && end !== -2) {
          selectionArr.push(`${info.name}:${start}-${end}`);
          start = -2;
          end = -2;
        } else if (start !== -2) {
          selectionArr.push(`${info.name}:${start}`);
        }
      } else if (nextSeqIdx - seqIdx === 1 && map[nextSeqIdx].length === 1) {
        end = nextSeqIdx;
      } else {
        if (start !== -2 && end !== -2) {
          selectionArr.push(`${info.name}:${start}-${end}`);
          start = -2;
          end = -2;
        } else if (start !== -2) {
          selectionArr.push(`${info.name}:${start}`);
          start = -2;
        }
      }
    }
  }
  return selectionArr.join(",");
}

export function getSelectionRange(
  plugin: PluginUIContext,
  ref: string,
  fileName: string
) {
  const structureSelection = plugin.managers.structure.selection;
  let text = "";
  const entry = structureSelection.entries.get(ref);
  if (entry && entry.structure) {
    const { structure, selection } = entry;
    const model = structure.model;

    if (selection.elements.length === 0) {
      Toast.warning(`You haven‘t selected any residue in file ${fileName}`);
      return "";
    }

    const chainMap: Record<string, ChainMapModel> = {};

    const { _rowCount: residueCount } = model.atomicHierarchy.residues;
    const { index: residueIndex, offsets } =
      model.atomicHierarchy.residueAtomSegments;
    const chainIndex = model.atomicHierarchy.chainAtomSegments.index;
    for (let rI = 0 as ResidueIndex; rI < residueCount; rI++) {
      const cI = chainIndex[offsets[rI]];
      const seqIdx = model.atomicHierarchy.residues.auth_seq_id.value(rI);
      const insCode =
        model.atomicHierarchy.residues.pdbx_PDB_ins_code.value(rI);
      const chainId = model.atomicHierarchy.chains.auth_asym_id.value(cI);

      if (!chainMap[chainId]) {
        chainMap[chainId] = {
          name: chainId,
          map: {
            [`${seqIdx}`]: [insCode],
          },
        };
      } else {
        if (!chainMap[chainId].map[`${seqIdx}`]) {
          chainMap[chainId].map[`${seqIdx}`] = [insCode];
        } else {
          chainMap[chainId].map[`${seqIdx}`].push(insCode);
        }
      }
    }

    const chainSelectionArr: string[] = [];

    selection.elements.forEach((e) => {
      const chainInfo: ChainInfoModel = {
        name: "",
        sequences: [],
        inscodes: [],
      };

      const { elements } = e.unit;

      if (OrderedSet.isInterval(e.indices)) {
        /* eslint-disable */
        // @ts-ignore: Unreachable code error
        e.indices = OrderedSet.toArray(e.indices);
        /* eslint-enable */
      }

      if ("residueIndex" in e.unit) {
        const unit = e.unit;
        OrderedSet.forEachSegment(
          e.indices,
          (i) => offsets[residueIndex[elements[i]]],
          (oI) => {
            const seqIdx =
              unit.model.atomicHierarchy.residues.auth_seq_id.value(
                unit.residueIndex[oI]
              );
            const insCode =
              unit.model.atomicHierarchy.residues.pdbx_PDB_ins_code.value(
                unit.residueIndex[oI]
              );
            const chainId =
              unit.model.atomicHierarchy.chains.auth_asym_id.value(
                unit.chainIndex[oI]
              );
            if (!chainId) {
              unit.model.atomicHierarchy.chains.label_asym_id.value(
                unit.chainIndex[oI]
              );
            }

            chainInfo.name = chainId;
            chainInfo.sequences.push(seqIdx);
            chainInfo.inscodes.push(insCode);
          }
        );
      }

      const targetChainMap = chainMap[chainInfo.name].map;

      const selectionText = getChainText(chainInfo, targetChainMap);

      chainSelectionArr.push(selectionText);
    });

    text = chainSelectionArr.join(",");
  } else {
    Toast.warning(`You haven‘t selected any residue in file ${fileName}`);
  }

  return text;
}

export function getRangeByChainName(chain: string, text: string) {
  const list = text.split(",");
  const targetList = list
    .filter((range) => {
      return chain === range[0];
    })
    .map((range) => {
      return range.slice(2);
    });

  return targetList.join(",");
}

export function findIntersection(arr1: string[], arr2: string[]) {
  return arr1.filter((value) => arr2.includes(value));
}

export function transformGermlineRecommendData(
  recSequenceNum: number,
  list: AdviserGermlinePosRecResultModel[]
) {
  const res: GermlinePositionModel[][] = [];
  for (let i = 0; i < recSequenceNum; i++) {
    res.push([]);
  }
  const len = list.length;
  for (let i = 0; i < len; i++) {
    const col = list[i];
    for (let j = 0; j < recSequenceNum; j++) {
      const o: GermlinePositionModel = {
        amino_acid: col.rec_aa_list[j],
        pos: col.pos,
        aa_frequency: col.aa_frequency,
      };
      res[j].push(o);
    }
  }

  const result = res.map((arr) => {
    return arr.reduce((ac, item) => {
      ac[item.pos] = item;
      return ac;
    }, {} as Record<string, GermlinePositionModel>);
  });

  return result;
}

export function transformPtmScoreData(data: PtmRiskChainModel) {
  const list = data.pos_attr.filter((item) => {
    return typeof item.freq === "number";
  });

  const map = list.reduce((ac, item) => {
    ac[item.pos] = item;
    return ac;
  }, {} as Record<string, PtmPosAttrModel>);

  return map;
}

export function transformMutation(
  mutationList: OptimizationAntibodyMutationModel[]
) {
  if (mutationList.length === 0) {
    return "-";
  }
  const list = mutationList.map((item) => {
    return `${item.origin}${item.pos}${item.mutation}`;
  });

  return list.join(",");
}

export function transformSurfaceData(data: SurfaceChainModel) {
  const map = data.pos_attr.reduce((ac, item) => {
    ac[item.pos] = item;
    return ac;
  }, {} as Record<string, SurfacePosAttrModel>);

  return map;
}

const specialSeq = [33, 61, 112];

export function sortPositionArr(arr: string[], scheme: SchemeEnum) {
  return arr.sort((a, b) => {
    const aMatch = a.match(/\d+/);
    const bMatch = b.match(/\d+/);
    if (!aMatch || !bMatch) {
      return 0;
    }

    // 提取数字部分
    const numA = parseInt(aMatch[0]);
    const numB = parseInt(bMatch[0]);

    // 提取字母部分
    const letterA = a.match(/[A-Z]+$/);
    const letterB = b.match(/[A-Z]+$/);

    // 比较数字部分
    if (numA !== numB) {
      return numA - numB;
    }

    // 数字相同，且是[33, 61, 112]的情况，按ASCII码降序排列
    if (scheme === SchemeEnum.Imgt && specialSeq.includes(numA)) {
      if (!letterA && letterB) {
        return 1; // 没有结尾字母的排在后面
      }
      if (letterA && !letterB) {
        return -1; // 有结尾字母的排在前面
      }

      // 数字相同，且都是112，按ASCII码降序排列
      if (letterA && letterB) {
        return letterB[0].localeCompare(letterA[0]);
      }

      // 数字相同，且都是112，且都没有结尾字母的情况
      return 0;
    }

    // 数字相同，但不是[33, 61, 112]的情况
    // 没有结尾字母的排在前面
    if (!letterA && letterB) {
      return -1;
    }
    if (letterA && !letterB) {
      return 1;
    }

    // 数字相同，且都没有字母部分
    if (!letterA && !letterB) {
      return 0;
    }

    if (!letterA || !letterB) {
      return 0;
    }
    // 数字相同，比较字母部分，按ASCII码升序排列
    return letterA[0].localeCompare(letterB[0]);
  });
}

export function alignPositionAttr(
  rawAttrs: ChainSeqPosModel[],
  newAttrs: ChainSeqPosModel[],
  posRecResult: PositionModel[],
  scheme: SchemeEnum,
  seq_align_type: SeqAlignTypeEnum,
  humanization?: ChainSeqPosModel[]
) {
  const rawPos = rawAttrs.map((item) => {
    return item.pos;
  });

  const newPos = newAttrs.map((item) => {
    return item.pos;
  });

  let recPos: string[] = [];

  let humanizationPos: string[] = [];

  if (seq_align_type === SeqAlignTypeEnum.ByPos) {
    recPos = posRecResult.map((item) => {
      return item.pos;
    });
  }

  if (humanization) {
    humanizationPos = humanization.map((item) => {
      return item.pos;
    });
  }

  const posSet = new Set([...rawPos, ...newPos, ...recPos, ...humanizationPos]);

  const allPos = [...posSet];

  const sortedAllPos = sortPositionArr(allPos, scheme);

  return sortedAllPos;
}

export function alignGermlinePositionAttr(
  rawAttrs: ChainSeqPosModel[],
  newAttrs: ChainSeqPosModel[],
  germlineResult: AdviserGermlinePosRecResultModel[],
  scheme: SchemeEnum,
  humanization?: ChainSeqPosModel[]
) {
  const rawPos = rawAttrs.map((item) => {
    return item.pos;
  });

  const newPos = newAttrs.map((item) => {
    return item.pos;
  });

  const germlinePos = germlineResult.map((item) => {
    return item.pos;
  });

  let humanizationPos: string[] = [];

  if (humanization) {
    humanizationPos = humanization.map((item) => {
      return item.pos;
    });
  }

  const posSet = new Set([
    ...rawPos,
    ...newPos,
    ...germlinePos,
    ...humanizationPos,
  ]);

  const allPos = [...posSet];

  const sortedAllPos = sortPositionArr(allPos, scheme);

  return sortedAllPos;
}

export function getChainPositionArray(
  chainHRaw: ScoreChainInfoModel,
  chainLRaw: ScoreChainInfoModel,
  chainHNew: ScoreChainInfoModel,
  chainLNew: ScoreChainInfoModel,
  parameters: AntibodyOptimizationParametersModel,
  recommendData?: AntibodyRecommendModel,
  germlineData?: AdviserGermlineSequenceModel
) {
  let chainHPositionArray: string[] = [];
  let chainLPositionArray: string[] = [];

  if (
    !parameters.adviser_type ||
    parameters.adviser_type === AdvisorEnum.AiCopilot
  ) {
    if (recommendData) {
      chainHPositionArray = alignPositionAttr(
        chainHRaw.seq_items,
        chainHNew.seq_items,
        recommendData.H.pos_rec_result,
        parameters.score_model.scheme,
        recommendData.seq_align_type
      );

      chainLPositionArray = alignPositionAttr(
        chainLRaw.seq_items,
        chainLNew.seq_items,
        recommendData.L.pos_rec_result,
        parameters.score_model.scheme,
        recommendData.seq_align_type
      );
    }
  }

  if (parameters.adviser_type === AdvisorEnum.GermlineSequence) {
    if (germlineData) {
      chainHPositionArray = alignGermlinePositionAttr(
        chainHRaw.seq_items,
        chainHNew.seq_items,
        germlineData.H.pos_rec_result,
        parameters.score_model.scheme
      );

      chainLPositionArray = alignGermlinePositionAttr(
        chainHRaw.seq_items,
        chainHNew.seq_items,
        germlineData.L.pos_rec_result,
        parameters.score_model.scheme
      );
    }
  }

  return [chainHPositionArray, chainLPositionArray];
}

export function getUnsavedSequencePostion(
  oldChain: ChainSeqPosModel[],
  newChain: ChainSeqPosModel[],
  scheme: SchemeEnum
) {
  const oldPos = oldChain.map((item) => {
    return item.pos;
  });

  const newPos = newChain.map((item) => {
    return item.pos;
  });

  const posSet = new Set([...oldPos, ...newPos]);

  const allPos = [...posSet];

  const sortedAllPos = sortPositionArr(allPos, scheme);

  return sortedAllPos;
}

export function sortResidue(a: string, b: string) {
  // 提取字母部分
  const chainA = a.match(/^[A-Z]+/);
  const chainB = b.match(/^[A-Z]+/);

  if (!chainA || !chainB) {
    return 0;
  }

  if (chainA[0] !== chainB[0]) {
    return chainA[0].localeCompare(chainB[0]);
  }

  const seqIdA = a.match(/\d+/);
  const seqIdB = b.match(/\d+/);

  if (!seqIdA || !seqIdB) {
    return 0;
  }

  // 提取数字部分
  const numA = parseInt(seqIdA[0]);
  const numB = parseInt(seqIdB[0]);

  // 比较数字部分
  if (numA !== numB) {
    return numA - numB;
  }

  // 提取字母部分
  const icodeA = a.match(/[A-Z]+$/);
  const icodeB = b.match(/[A-Z]+$/);

  // 没有结尾字母的排在前面
  if (!icodeA && icodeB) {
    return -1;
  }
  if (icodeA && !icodeB) {
    return 1;
  }

  // 数字相同，且都没有字母部分
  if (!icodeA && !icodeB) {
    return 0;
  }

  if (!icodeA || !icodeB) {
    return 0;
  }
  // 数字相同，比较字母部分，按ASCII码升序排列
  return icodeA[0].localeCompare(icodeB[0]);
}

export function getInteractionTableText(list: InteractionEffectModel[]) {
  const textList: string[] = [
    "Buried1,Res1,AA1,Atom1,Interaction Type,dASA,Res2,AA2,Atom2,Buried1",
  ];
  list.forEach((item) => {
    const text = `"${
      typeof item.buried1 === "number" ? (item.buried1 * 100).toFixed(0) : ""
    }","${item.res1}","${item.aa1}","${item.atom1}","${interactionTypeLabel(
      item.interaction_type
    )}","${item.dasa.toFixed(0)}","${item.res2}","${item.aa2}","${
      item.atom2
    }","${
      typeof item.buried2 === "number" ? (item.buried2 * 100).toFixed(0) : ""
    }"`;

    textList.push(text);
  });

  return textList.join("\n");
}
