import React, { useState, useEffect, useLayoutEffect } from "react";
import tradeMark from "@assets/index-image/trade-mark.png";
import wechat from "@assets/index-image/wechat.png";
import x from "@assets/index-image/x.png";
import linkedin from "@assets/index-image/linkedin.png";
import qrCode from "@assets/home-image/qrcode.png";
import globalIcon from "@assets/home-image/global.png";
import { RootState, AppDispatch } from "@state/store";
import { useSelector, useDispatch } from "react-redux";
import { setLang } from "@state/home/reducer";
import { useSearchParams } from "react-router-dom";
import { footerConfig } from "@services/home/config/home.config";
import { HomeFooterModel } from "@services/home/model/home.model";

import styles from "./index.module.css";

const HomeFooter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const lang = useSelector((state: RootState) => state.home.lang);

  const [config, setConfig] = useState<HomeFooterModel>();
  const [year, setYear] = useState("");

  function onChangeLan() {
    if (lang === "en") {
      dispatch(setLang("cn"));
      setSearchParams((params) => {
        params.set("lang", "cn");
        return params;
      });
    } else {
      dispatch(setLang("en"));
      setSearchParams((params) => {
        params.set("lang", "en");
        return params;
      });
    }
  }

  useEffect(() => {
    const date = new Date();
    setYear(`${date.getFullYear()}`);
  }, []);

  useLayoutEffect(() => {
    if (lang) {
      setConfig(footerConfig[lang]);
    }
  }, [lang]);

  return (
    <div className={styles.footer}>
      {config && (
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.textTitle}>{config.quickLink.title}</div>

              {config.quickLink.links.map((link) => {
                return (
                  <div className={styles.subText} key={link.name}>
                    <a
                      href={link.url}
                      rel="noreferrer"
                      target={link.external ? "_blank" : "_self"}
                      className={styles.whiteLink}
                    >
                      {link.name}
                    </a>
                  </div>
                );
              })}

              <div className={styles.switchContainer} onClick={onChangeLan}>
                <img src={globalIcon} className={styles.img} />
                <span className={styles.text}>
                  {config.quickLink.switchText}
                </span>
              </div>
            </div>
            <div className={styles.col}>
              {config.locations.map((location) => {
                return (
                  <React.Fragment key={location.name}>
                    <div className={styles.textTitle}>{location.name}</div>
                    <div className={styles.subText}>{location.value}</div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className={styles.col}>
              {config.contacts.slice(0, 2).map((contact) => {
                return (
                  <React.Fragment key={contact.value}>
                    <div className={styles.textTitle}>{contact.name}</div>
                    <div className={styles.subText}>
                      <a
                        href={`mailto:${contact.value}`}
                        className={styles.whiteLink}
                      >
                        {contact.value}
                      </a>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className={styles.col}>
              {config.contacts.slice(2, 4).map((contact) => {
                return (
                  <React.Fragment key={contact.value}>
                    <div className={styles.textTitle}>{contact.name}</div>
                    <div className={styles.subText}>
                      <a
                        href={`mailto:${contact.value}`}
                        className={styles.whiteLink}
                      >
                        {contact.value}
                      </a>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className={styles.imageContainer}>
            <a href="https://www.biogeom.com" target="_black">
              <img className={styles.tradeMark} src={tradeMark} />
            </a>

            <span className={styles.iconContainer}>
              <a className={`${styles.imgContainer} ${styles.popoverWrapper}`}>
                <img className={styles.icon} src={wechat} />
                <div className={styles.popoverContent}>
                  <img
                    className={`${styles.icon} ${styles.img}`}
                    src={qrCode}
                  />
                </div>
              </a>
              <a
                className={styles.imgContainer}
                href="https://twitter.com/BioGeometryAI"
              >
                <img className={styles.icon} src={x} />
              </a>
              <a
                className={styles.imgContainer}
                href="https://www.linkedin.com/company/biogeometry"
              >
                <img className={styles.icon} src={linkedin} />
              </a>
            </span>
          </div>
          <div className={styles.copyRightWrapper}>
            Copyright&nbsp;©&nbsp;{year}&nbsp;
            <a
              href="https://www.biogeom.com"
              target="_black"
              className={styles.link}
            >
              百奥几何
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_black"
              className={styles.link}
            >
              京ICP备2022032231号-1
            </a>
            &nbsp;|&nbsp;
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040974"
              target="_black"
              className={styles.link}
            >
              京公网安备 11010802040974号
            </a>
            &nbsp;|&nbsp;隐私条款
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeFooter;
